import React from 'react';
import { HStack, Flex } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { SUPPLY_CHAIN_VIEW_MODE } from '../../../../slices/markets/marketsConfig';
import { RadioGroup, Radio } from '@/components/ui/radio';

const viewModeOptions = Object.entries(SUPPLY_CHAIN_VIEW_MODE).map(([key, value]) => ({
  label: value.charAt(0).toUpperCase() + value.substr(1).toLowerCase(),
  value,
}));

export default function SupplyChainViewModeControl(props) {
  const { controlState, onViewModeChange } = props;
  return (
    <Flex alignItems="center" justifyContent="end">
      <span style={{ marginRight: '20px', color: 'rgba(11, 20, 53, 0.7)' }}>View by</span>
      <RadioGroup value={controlState.viewMode} onValueChange={e => onViewModeChange(e.value)}>
        <HStack alignItems="center" justifyContent="end">
          {viewModeOptions.map(option => (
            <Radio key={option.value} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </HStack>
      </RadioGroup>
    </Flex>
  );
}

// TODO define shape
SupplyChainViewModeControl.propTypes = {
  controlState: PropTypes.shape(),
  onViewModeChange: PropTypes.func,
};
