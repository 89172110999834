import React from 'react';
import { formatSI, formatChange } from '../../../../modules/format';
import LineChart from '../../../../shared/LineChart/LineChart';
import styles from './McMenuTable.module.css';
import BigMac from './bigmac.png';
import QuarterPounder from './quarterpounder.png';
import Angus from './angus.png';
import PropTypes from 'prop-types';
import { Tooltip } from '@/components/ui/tooltip';
import { Text } from '@chakra-ui/react';

const icons = {
  '10:1': BigMac,
  '4:1': QuarterPounder,
  Angus,
};

const DollarIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 12C23 18.075 18.075 23 12 23C5.92504 23 1 18.075 1 12C1 5.92504 5.92504 1 12 1C18.075 1 23 5.92504 23 12Z"
      fill="#FC5D36"
    />
    <path
      d="M9.2312 14.4235C9.2312 15.7618 10.4708 16.8466 12.0004 16.8466C13.5301 16.8466 14.7697 15.7618 14.7697 14.4235C14.7697 13.0853 13.4882 12.3812 12.0004 12.0005C10.54 11.6349 9.2312 10.9156 9.2312 9.57737C9.2312 8.23914 10.4708 7.1543 12.0004 7.1543C13.5301 7.1543 14.7697 8.23914 14.7697 9.57737"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12 5.76953V7.15415" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 16.8457V18.2303" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 12C23 18.075 18.075 23 12 23C5.92504 23 1 18.075 1 12C1 5.92504 5.92504 1 12 1C18.075 1 23 5.92504 23 12V12Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 12C23 18.075 18.075 23 12 23C5.92504 23 1 18.075 1 12C1 5.92504 5.92504 1 12 1C18.075 1 23 5.92504 23 12V12Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default function McMenuTable(props) {
  const { marketsMcMenuData } = props;

  return (
    <div className={styles.mcMenu}>
      {marketsMcMenuData.map(x => {
        const priceData = x.movementPeriodPrices.filter(x => x.price !== null);
        const filteredMovementData = x.movement.filter(x => x.price !== null);

        const diff = priceData.length ? priceData[0].price - priceData[priceData.length - 1].price : 0;
        const chart = <LineChart data={filteredMovementData} />;

        return (
          <div className={styles.mcMenuItem} key={x.id} style={{ width: `calc(100% / ${marketsMcMenuData.length})` }}>
            <div className={styles.burger}>
              <img src={icons[x.label]} alt="Burger" />
            </div>
            <div className={styles.label}>{x.label}</div>
            <div className={styles.price}>
              <Text textStyle="xl">{x.price ? `$${formatSI(2, 2)(x.price)}` : '-'}</Text>
            </div>
            <div className={styles.change}>
              {formatChange('negative')(diff)}
              <div>
                {formatSI(3)(Math.abs(diff))}
                <div>change week on week</div>
              </div>
            </div>
            <Tooltip content="YTD price movement">
              <div className={styles.movementChart}>{chart}</div>
            </Tooltip>
            {x.insight && (
              <div className={styles.insight}>
                <div className={styles.insightTitle}>
                  <DollarIcon />
                  {x.insight.title}
                </div>
                <div>{x.insight.text}</div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

McMenuTable.propTypes = {
  marketsMcMenuData: PropTypes.arrayOf(PropTypes.string).isRequired,
};
