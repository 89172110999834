import React from 'react';
import PropTypes from 'prop-types';
import { getDayOfYear } from 'date-fns';
import { scaleLinear, scaleBand, ascending, format, line } from 'd3';

import useDimensions from '../../../modules/hooks/useDimensions';
import { flatten } from '../../../modules/utils';
import ColumnXAxis from './ColumnXAxis';
import ColumnYAxis from './ColumnYAxis';
import styles from './ColumnChart.module.css';
import { Box, Text } from '@chakra-ui/react';

const defaultPadding = {
  top: 30,
  left: 60,
  right: 20,
  bottom: 50,
};

const zeroMaxGenerator = series => {
  return {
    min: 0,
    max: Math.max(...flatten(series.map(({ values }) => values.map(([, value]) => value)))),
  };
};

const readLabelFromFirstSeries = series => (series[0] && series[0].labels) || [];

const hoverFormatter = format(',.0f');

export default function ColumnChart({
  data,
  filters,
  colors,
  padding = defaultPadding,
  minMaxGenerator = zeroMaxGenerator,
  labelGenerator = readLabelFromFirstSeries,
  leftTitle = '',
  rightTitle = '',
  mode = 'column',
}) {
  const [ref, dimensions] = useDimensions();
  const [hover, setHover] = React.useState(null);

  if (!dimensions) {
    return (
      <div className={styles.chartWrapper}>
        <svg ref={ref} />
      </div>
    );
  }

  const { series } = data;
  const labels = labelGenerator(series);

  const { width, height } = dimensions;
  const { min, max } = minMaxGenerator(series);
  const paddingInner = 0.3;
  const paddingOuter = 0.3;

  let scaleX = scaleBand()
    .domain(labels)
    .range([0 + padding.left, width - padding.right])
    .paddingInner(paddingInner)
    .paddingOuter(paddingOuter);
  const scaleY = scaleLinear()
    .domain([min, max])
    .range([0, height - padding.bottom - padding.top]);

  if (mode === 'line') {
    scaleX = scaleLinear()
      .domain([1, 366])
      .range([0 + padding.left, width - padding.right]);
  }

  const chartBottom = height - padding.bottom;
  let svgSeries;
  if (mode === 'column') {
    svgSeries = series.map(({ key: seriesKey, values }, seriesIndex) => {
      return values.map(([label, value]) => {
        const barHeight = scaleY(value);
        const barWidth = scaleX.bandwidth() / series.length;
        const x = scaleX(label) + seriesIndex * barWidth;
        const y = chartBottom - barHeight;

        return (
          <rect
            key={label}
            x={x}
            y={y}
            width={barWidth}
            height={barHeight}
            fill={colors[seriesKey]}
            onMouseEnter={() => setHover({ value, label })}
            onMouseLeave={() => setHover(null)}
          />
        );
      });
    });
  } else {
    const lineGenerator = line()
      .x(([label]) => scaleX(getDayOfYear(new Date(+label))))
      .y(([, value]) => chartBottom - scaleY(value));
    svgSeries = series.map(({ key: seriesKey, values: rawValues }) => {
      const values = rawValues.sort((a, b) => ascending(a[0], b[0]));
      return <path key={seriesKey} d={lineGenerator(values)} className={styles.line} stroke={colors[seriesKey]} />;
    });
  }

  return (
    <>
      <Box className="column-chart">
        <Box flex="auto" className={styles.left}>
          <Text>{leftTitle}</Text>
        </Box>
        <Box flex="auto" className={styles.right}>
          <Text style={{ paddingRight: padding.right }}>{rightTitle}</Text>
        </Box>
      </Box>
      <div ref={ref} className={styles.chartWrapper}>
        <svg style={{ imageRendering: 'crisp-edges' }}>
          <g className={styles.xAxisTick} transform={`translate(0,${height - padding.bottom + 2})`}>
            <ColumnXAxis scale={scaleX} frequency={filters.frequency} mode={mode} />
          </g>
          <g className="y-axis">
            <ColumnYAxis scale={scaleY} base={chartBottom} yAxisRange={scaleX.range()} />
          </g>
          <g className="columns">{svgSeries}</g>
          {hover && (
            <text
              className={styles.hoverLabel}
              x={scaleX(hover.label) + scaleX.bandwidth() / 2}
              y={chartBottom - scaleY(hover.value) - 10}
            >
              <tspan className={styles.hoverLabelValue}>{hoverFormatter(hover.value)}</tspan>{' '}
              <tspan>{filters.units}</tspan>
            </text>
          )}
        </svg>
      </div>
    </>
  );
}

ColumnChart.propTypes = {
  colors: PropTypes.shape({
    [PropTypes.string]: PropTypes.string.isRequired,
  }).isRequired,
  padding: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
  }),
  minMaxGenerator: PropTypes.func,
  labelGenerator: PropTypes.func,
  filters: PropTypes.instanceOf(Object),
  leftTitle: PropTypes.string,
  rightTitle: PropTypes.string,
  mode: PropTypes.oneOf(['column', 'line']),
  data: PropTypes.shape({
    series: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        values: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])))
          .isRequired,
      })
    ),
  }),
};
