import React, { Component } from 'react';
import groupBy from 'lodash/groupBy';
import PropTypes from 'prop-types';
import ClassicSideBar from './navigation/ClassicSidebarMenu';
import NextGenSideBar from './navigation/NextGenSidebarMenu';
import routeConfigLegacy from '../routeConfigLegacy';
import routeConfig from '../routeConfig';
import withRouter from './utilities/withRouter';

const propTypes = {
  routeConfig: PropTypes.shape({
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        authConfig: PropTypes.shape({
          requiredPermissions: PropTypes.arrayOf(PropTypes.string),
          anyOfPermissions: PropTypes.arrayOf(PropTypes.string),
        }),
      })
    ),
  }),
  nextGen: PropTypes.bool.isRequired,
  dynamicNavigationLinks: PropTypes.arrayOf(
    PropTypes.shape({
      group_name: PropTypes.string,
      order_in_group: PropTypes.number,
      hidden: PropTypes.bool,
      name: PropTypes.string,
      required_permissions: PropTypes.arrayOf(PropTypes.string),
      any_of_permissions: PropTypes.arrayOf(PropTypes.string),
      icon: PropTypes.string,
      url: PropTypes.string,
      external: PropTypes.bool,
      icon_set: PropTypes.string,
    })
  ),
};

// eslint-disable-next-line react/prefer-stateless-function
class DynamicSidebarMenu extends Component {
  render() {
    const { dynamicNavigationLinks } = this.props;
    // adding external navigation links to routeConfigs
    let dynamicLinks = { ...dynamicNavigationLinks };
    dynamicLinks = groupBy(dynamicLinks, 'group_name');
    Object.entries(dynamicLinks).forEach(([groupName, children]) => ({
      [groupName]: children.sort((a, b) => (a.order_in_group > b.order_in_group ? 1 : -1)),
    }));
    const prepareddynamicLinks = Object.entries(dynamicLinks).reduce((groupAgg, [groupName, childrenEntries]) => {
      const groupChildren = childrenEntries.reduce((agg, child) => {
        if (!child.hidden) {
          agg.push({
            name: child.name,
            authConfig: {
              requiredPermissions: child.required_permissions.length > 0 ? child.required_permissions : undefined,
              anyOfPermissions: child.any_of_permissions.length > 0 ? child.any_of_permissions : undefined,
            },
            component: () => {},
            icon: `${child.icon}`,
            routeConfig: {
              exact: true,
              path: `${child.url}`,
              target: child.external ? '_blank' : '',
              isExternalNavigation: true,
            },
          });
        }
        return agg;
      }, []);
      // add not hidden children to group
      if (groupChildren.length > 0) {
        groupAgg.push({
          name: groupName,
          authConfig: {
            requiredPermissions: ['navigation_read'],
          },
          component: () => {},
          icon: `${childrenEntries[0].icon_set}`,
          children: groupChildren,
        });
      }
      return groupAgg;
    }, []);

    const { nextGen } = this.props;

    if (nextGen) {
      const extendedRouteConfig = {
        groups: routeConfig.groups.concat(prepareddynamicLinks),
      };
      return <NextGenSideBar routeConfig={extendedRouteConfig} />;
    }
    const extendedRouteConfigLegacy = {
      groups: routeConfigLegacy.groups.concat(prepareddynamicLinks),
    };
    return <ClassicSideBar routeConfig={extendedRouteConfigLegacy} />;
  }
}

DynamicSidebarMenu.propTypes = propTypes;

export default withRouter(DynamicSidebarMenu);
