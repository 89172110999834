import React from 'react';
import PropTypes from 'prop-types';
import { HStack } from '@chakra-ui/react';
import { CURRENCY } from '../../../../slices/markets/marketsConfig';
import { RadioGroup, Radio } from '@/components/ui/radio';

export default function FilterCurrency(props) {
  const { activeCurrency, onActiveCurrencyChange } = props;
  return (
    <div>
      <RadioGroup value={activeCurrency} onValueChange={e => onActiveCurrencyChange(e.value)}>
        <HStack alignItems="center" justifyContent="start">
          <Radio value={CURRENCY.AUD}>AUD</Radio>
          <Radio value={CURRENCY.USD}>USD</Radio>
        </HStack>
      </RadioGroup>
    </div>
  );
}

FilterCurrency.propTypes = {
  activeCurrency: PropTypes.string.isRequired,
  onActiveCurrencyChange: PropTypes.func.isRequired,
};
