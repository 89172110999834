import React from 'react';
import moment from 'moment';
import { startOfWeek, endOfWeek, subDays, addDays } from 'date-fns';
import PropTypes from 'prop-types';
import { RadioGroup, Radio } from '@/components/ui/radio';
import { Flex, HStack } from '@chakra-ui/react';
import DatetimeRangePicker from 'react-datetime-range-picker';

export default function MarketFilterTimePeriod(props) {
  const { timePeriod, onTimePeriodChange } = props;

  const [type, setType] = React.useState('this_week');

  const thisWeekStart = startOfWeek(new Date()).getTime();
  const thisWeekEnd = endOfWeek(new Date()).getTime();

  const lastWeekStart = startOfWeek(subDays(new Date(), 7)).getTime();
  const lastWeekEnd = endOfWeek(subDays(new Date(), 7)).getTime();

  const nextWeekStart = startOfWeek(addDays(new Date(), 7)).getTime();
  const nextWeekEnd = endOfWeek(addDays(new Date(), 7)).getTime();

  return (
    <div>
      <Flex alignItems="center" justifyContent="right" gap="20px">
        <RadioGroup value={type} onValueChange={e => setType(e.value)}>
          <HStack alignItems="center">
            <Radio value="last_week" onClick={() => onTimePeriodChange([lastWeekStart, lastWeekEnd])}>
              Last week
            </Radio>
            <Radio value="this_week" onClick={() => onTimePeriodChange([thisWeekStart, thisWeekEnd])}>
              This week
            </Radio>
            <Radio value="next_week" onClick={() => onTimePeriodChange([nextWeekStart, nextWeekEnd])}>
              Next week
            </Radio>
          </HStack>
        </RadioGroup>
        {/* <DatePicker.RangePicker
          picker="week"
          value={timePeriod.map(d => moment(d))}
          format="DD/MM/YYYY"
          onChange={newPeriod => {
            if (!newPeriod) return;
            // Unset the radio box if custom dates are selected.
            setType('');
            onTimePeriodChange(newPeriod.map(m => m.toDate().getTime()));
          }}
        /> */}
        <DatetimeRangePicker
          className="date-range-picker"
          inputProps={{
            placeholder: 'Date range',
          }}
          closeOnSelect
          startDate={moment(timePeriod[0]).toDate()}
          endDate={moment(timePeriod[1]).toDate()}
          onChange={e => {
            onTimePeriodChange([moment(e.startDate).toDate().getTime(), moment(e.endDate).toDate().getTime()]);
            setType(undefined);
          }}
          timeFormat={false}
          dateFormat="DD/MM/YYYY"
        />
      </Flex>
    </div>
  );
}

MarketFilterTimePeriod.propTypes = {
  timePeriod: PropTypes.string.isRequired,
  onTimePeriodChange: PropTypes.func.isRequired,
};
