import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import routeConfig from '../routeConfig';

const findRouteName = (routes, path) => {
  let routeName = null;

  routes.some(route => {
    if (route.routeConfig?.path === path || route.routeConfig?.defaultPath === path) {
      routeName = route.name;
      return true;
    }
    if (route.children) {
      routeName = findRouteName(route.children, path);
      return routeName !== null;
    }
    return false;
  });

  return routeName;
};

const AppRoutesPageTitles = () => {
  const location = useLocation();
  const [routeName, setRouteName] = useState('');

  useEffect(() => {
    const currentPath = location.pathname;
    const name = findRouteName(routeConfig.groups, currentPath);
    setRouteName(name || '');
  }, [location.pathname]);

  return routeName;
};

export default AppRoutesPageTitles;
