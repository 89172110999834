import React from 'react';
import { Route, Routes } from 'react-router';
// import Helmet from 'react-helmet';
import './AppRoutes.scss';
import AuthGate from '@/containers/auth/AuthGate';
import routeConfig from '../routeConfig';
import routeConfigLegacy from '../routeConfigLegacy';
import { Helmet } from 'react-helmet';
// import { getLevelName } from '../utils';
// import { useTheme } from '@chakra-ui/react';

const renderLevel = level => {
  return (
    <Route
      key={level.routeConfig?.path}
      path={level.routeConfig?.path}
      element={
        <AuthGate {...level.authConfig}>
          <Helmet>
            <title>
              {window.location.hostname.includes('.agilechain') ? 'AgileChain' : 'BOAB'} - {level.name}
            </title>
          </Helmet>
          <div className={`cfc-content ${level.className}`}>{level.component && <level.component />}</div>
        </AuthGate>
      }
    />
  );
};

const flattenRoutes = config => {
  const routes = [];

  const extractRoutes = levels => {
    levels.forEach(level => {
      routes.push(level); // Add the current level
      if (level.children) {
        extractRoutes(level.children); // Recursively add children
      }
    });
  };

  config.groups.forEach(group => extractRoutes(group.children));
  return routes;
};

const AppRoutes = ({ nextGen }) => {
  const rc = nextGen ? routeConfig : routeConfigLegacy;
  const allRoutes = flattenRoutes(rc);

  return <Routes>{allRoutes.map(level => renderLevel(level))}</Routes>;
};

export default AppRoutes;
