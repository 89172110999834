import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { addUserSignature, fetchUserSignature, setUserSignatureLoading } from '../../actions/actions_users';
import { Box, Button, Grid, GridItem, Text } from '@chakra-ui/react';

class SignatureUpload extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    signature: PropTypes.string,
    token: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
    }),
    onValidationChange: PropTypes.func,
    setSignatureUpload: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      file: false,
      fileupload: false,
      error: null,
    };
  }

  componentDidMount() {
    const { dispatch, user, token } = this.props;
    dispatch(fetchUserSignature(user, token));
    this.validateSignature();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.signature !== this.props.signature) {
      this.validateSignature();
    }
    if (this.props.signature) {
      this.props.setSignatureUpload(true);
    } else if (!this.state.fileupload) {
      this.props.setSignatureUpload(false);
    } else {
      this.props.setSignatureUpload(true);
    }
  }

  validateSignature = () => {
    const { signature } = this.props;
    const { file, fileupload } = this.state;

    let error = null;

    if (!signature && !file) {
      error = 'Signature is required';
    } else if (file && !fileupload) {
      error = 'Please upload the file';
    }

    this.setState({ error });
  };

  onFieldChange = e => {
    const file = e?.target?.files[0];
    this.setState(
      {
        file,
        fileupload: false,
      },
      () => {
        this.validateSignature();
        this.props.setSignatureUpload(false);
      }
    );
  };

  save = () => {
    const { dispatch, user, token, setSignatureUpload } = this.props;
    const { file } = this.state;

    if (!file) {
      this.setState({ error: 'Please select a file first' });
      return;
    }

    const payload = {
      file,
      user_id: user.id,
    };

    dispatch(setUserSignatureLoading());
    dispatch(addUserSignature(payload, token));

    this.setState({ fileupload: true }, () => {
      setSignatureUpload(true);
      this.validateSignature();
    });
  };

  render() {
    const { disabled, signature, loading } = this.props;
    const { file, error } = this.state;

    const fileStyle = {
      border: '1px solid grey',
      backgroundColor: 'grey',
      height: '30px',
      background: '#999999',
      color: 'white',
      borderRadius: '5px',
      padding: '5px 10px',
    };

    return (
      <Box width="100%">
        <Box marginBottom="10px" style={{ margin: '10px', padding: '0' }}>
          <h5>Signature File</h5>
          <hr />
        </Box>
        <Grid templateColumns="repeat(2, 1fr)" gap="6">
          <GridItem
            display="flex"
            gap="20px"
            border="1px solid #e4e4e7"
            padding="20px"
            alignItems="center"
            borderRadius="6px"
          >
            <h6>Select signature file</h6>
            <Box>
              <label className="btn btn-sm btn-info btn-file" style={fileStyle}>
                Choose file...
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  disabled={disabled}
                  onChange={this.onFieldChange}
                  required={!signature}
                />
                <span hidden={!file}>
                  &nbsp;&nbsp;
                  <i className="flaticon-interface" style={{ fontSize: 'inherit' }} />
                </span>
              </label>
            </Box>
            <Box>
              <Button
                variant="outline"
                className="btn btn-success btn-sm"
                colorScheme="actionPrimary"
                size="sm"
                onClick={this.save}
                disabled={!file}
              >
                <Text as="p" fontSize="14px" color="#fff">
                  Upload
                </Text>
              </Button>

              {error && (
                <div className="col-sm-12" style={{ color: 'red', marginTop: '5px' }}>
                  {error}
                </div>
              )}
            </Box>
          </GridItem>
          <GridItem colSpan={1}>
            <Box className="col-sm-4">
              <h5>Your signature:</h5>
              &nbsp;&nbsp;
              {signature && !loading ? (
                <a href={signature} target="_blank" rel="noreferrer">
                  Click to download
                </a>
              ) : null}
              {!signature && !loading ? <span>No signature loaded</span> : null}
              {loading ? <img src="img/loader.gif" alt="loading" width={100} /> : null}
            </Box>
          </GridItem>
        </Grid>
      </Box>
    );
  }
}

export default SignatureUpload;
