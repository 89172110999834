import React from 'react';
import PropTypes from 'prop-types';
import { HStack } from '@chakra-ui/react';
import { MARKET_FOCUS } from '../../../../slices/markets/marketsConfig';
import { RadioGroup, Radio } from '@/components/ui/radio';

export default function FilterFocus(props) {
  const { activeFocus, onActiveFocusChange } = props;
  return (
    <div>
      <RadioGroup value={activeFocus} onValueChange={e => onActiveFocusChange(e.value)}>
        <HStack alignItems="center" justifyContent="start">
          <Radio value={MARKET_FOCUS.DOMESTIC}>Domestic</Radio>
          <Radio value={MARKET_FOCUS.EXPORT}>Export</Radio>
        </HStack>
      </RadioGroup>
    </div>
  );
}

FilterFocus.propTypes = {
  activeFocus: PropTypes.string.isRequired,
  onActiveFocusChange: PropTypes.func.isRequired,
};
