import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Routes, BrowserRouter as Router, Navigate } from 'react-router';
import { closeError } from '../actions/index';
import { autoQueryStringLogin, checkForToken } from '../actions/actions_auth';
// eslint-disable-next-line import/no-cycle
import DynamicLayoutContainer from '@/containers/layout/DynamicLayoutContainer';

import Login from '../containers/pages/Login';
import './App.scss';
import './analyticDashboard.scss';
import Maintenance from './pages/Maintenance';
import ResetPassword from './pages/ResetPassword';
import browserHistory from './utilities/BrowserHistory';
import AppRoutes from './AppRoutes';
import AppError from '../containers/utilities/AppError';
import './utilities/FetchInterceptor';
import { Flex, Spinner } from '@chakra-ui/react';

// Hacky maintenance mode indicator. This should change to be
// a database switch, but in the meantime this will have to work.

const MAINTENANCE_MODE = false;

function getParameterByName(name, url = window?.location?.href) {
  const nameWithRegex = name.replace(/[[\]\\]/g, '\\$&');
  const regex = new RegExp(`[?&]${nameWithRegex}(=([^&#]*)|&|#|$)`);

  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

class App extends Component {
  constructor(props) {
    super(props);
    let url = window.location?.pathname + window.location.search;
    if (url.indexOf('?') > -1) {
      url = url.substring(0, url.indexOf('?'));
    }
    this.state = {
      relative_path: window.location?.pathname + window.location.search,
      // path_no_query_string: url,
      loading: true,
    };
  }

  componentDidMount() {
    const { relative_path } = this.state;
    const { dispatch } = this.props;
    // Check for automatic login via querystring
    // Exception for reset password page
    if (relative_path.indexOf('/reset-password') === -1 && relative_path.indexOf('/login') === -1) {
      if (getParameterByName('token')) {
        dispatch(autoQueryStringLogin(getParameterByName('token')));
        window.history.pushState(null, '', window.location.href.split('?')[0]);
      } else {
        dispatch(checkForToken(browserHistory));
      }
    }

    setTimeout(() => this.setState({ loading: false }), 200);
  }

  componentDidUpdate(prevProps) {
    const { user, dispatch } = this.props;
    if (user !== prevProps.user && !user) {
      browserHistory.push('/login');
    }
    // Remove old error states that are not relevant for updated components
    dispatch(closeError(false));
  }

  render() {
    const { user } = this.props;

    if (MAINTENANCE_MODE) {
      return <Maintenance />;
    }

    if (this.state.loading) {
      return (
        <Flex h="100vh" align="center" justify="center">
          <Spinner />
        </Flex>
      );
    }

    return (
      <Router>
        <div>
          <AppError />
          {user ? (
            <DynamicLayoutContainer>
              <AppRoutes nextGen={user.next_gen_ui} />
            </DynamicLayoutContainer>
          ) : (
            <Routes>
              {window.location.pathname.includes('reset-password') ? (
                <Route path="*" element={<ResetPassword />} />
              ) : (
                <Route path="/login" element={<Login />} />
              )}
            </Routes>
          )}
        </div>
        <Routes>
          <Route path="/logout" element={<Navigate key={Math.random()} to="/login" replace />} />
        </Routes>
      </Router>
    );
  }
}

App.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    next_gen_ui: PropTypes.bool,
  }),
  dispatch: PropTypes.func.isRequired,
};

export default App;
