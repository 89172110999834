import React from 'react';
import { scaleLinear } from 'd3';
import moment from 'moment';
import { format } from 'date-fns';
import styles from './QualityPackerPlantScorecard.module.css';
import BaseChart from '../../treasury/BaseChart/BaseChart';
import { formatWithCommas } from '../../../../modules/format';
import PropTypes from 'prop-types';
import { random } from 'lodash';
import { Tooltip } from '@/components/ui/tooltip';
import { Text, Box, HStack } from '@chakra-ui/react';
import { RadioGroup, Radio } from '@/components/ui/radio';

const Triangle = props => (
  <svg width="9" height="9" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9 9V0L0 9H9Z" fill="currentColor" />
  </svg>
);

const Pointer = () => {
  return (
    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.17542 6.46686C4.57325 6.91474 5.27291 6.91474 5.67073 6.46686L9.44054 2.22268C10.0134 1.5777 9.55556 0.558594 8.69289 0.558594H1.15327C0.290591 0.558594 -0.167281 1.5777 0.405612 2.22268L4.17542 6.46686Z"
        fill="#0B1435"
      />
    </svg>
  );
};

const getscoretooltiptext = bucketValue => {
  if (bucketValue === -1) {
    return 'invalid score';
  }
  if (bucketValue === 0) {
    return 'scored 0';
  }
  if (bucketValue === 10) {
    return 'scored below 60';
  }
  return `scored above ${bucketValue}`;
};

export default function QualityPackerPlantScorecard(props) {
  const { scorecardData, singlePackerPlant, formatIntervals, timePeriod } = props;
  const [dataType, setDataType] = React.useState('rank');
  const [bucketWidthMode, setBucketWidthMode] = React.useState('count');
  const ticksNumber = 4;

  const getBorderRadius = (index, length) => {
    if (index === 0) return '5px 0 0 5px';
    if (index === length - 1) return '0 5px 5px 0';
    return 0;
  };

  const getColor = score => {
    // if (score === -1) {
    //   return '#BEBEBE';
    // }
    // if (score === 0) {
    //   return '#F8F8F8';
    // }
    if (score < 60 && score > 0) {
      return '#FEC5B8';
    }
    if (score >= 60 && score < 80) {
      return '#E3E8F9';
    }
    if (score >= 80 && score < 90) {
      return '#D0F1EE';
    }
    if (score >= 90) {
      return '#3DE5C7';
    }

    return '#F8F8F8';
  };
  const singlePackerPlantScore =
    singlePackerPlant && !singlePackerPlant.isPacker
      ? scorecardData.allPackerPlants.find(x => x.packer_plant_name === singlePackerPlant.name)?.score
      : undefined;

  const singlePackerPlantRank =
    singlePackerPlant && !singlePackerPlant.isPacker
      ? scorecardData.rankByPeriod?.filter(x => x.date === moment(timePeriod).format('YYYY-MM-01'))[0]?.value
      : undefined;

  const customContent = (barData, scales) => {
    return <Range data={barData} scales={scales} columns={['value']} showRange={dataType === 'rank'} />;
  };

  const maxNumOfPackerPlants = React.useMemo(() => {
    if (scorecardData.rankByPeriod) {
      const maxPlants = Math.max(...scorecardData.rankByPeriod.map(x => x.total));
      const y = scaleLinear().domain([0, maxPlants]).nice(ticksNumber);
      return Math.max.apply(null, y.domain());
    }
    return 0;
  }, [scorecardData]);

  const barScoreCardData = { ...scorecardData, buckets: scorecardData.buckets.filter(b => b.bucket > 0) };
  barScoreCardData.total = {
    count: barScoreCardData.buckets.reduce((acc, bucket) => {
      return acc + bucket.count;
    }, 0),
    volume: barScoreCardData.buckets.reduce((acc, bucket) => {
      return acc + bucket.volume;
    }, 0),
  };

  return (
    <Box>
      <Box>
        <Box my="5" display="flex" justifyContent="end" alignItems="center">
          <RadioGroup
            onValueChange={e => setBucketWidthMode(e.value)}
            defaultValue={bucketWidthMode}
            value={bucketWidthMode}
            size="sm"
          >
            <HStack gap="5">
              <Radio key={1} value="count">
                By score
              </Radio>
              <Radio key={2} value="volume">
                Proportional to volume
              </Radio>
            </HStack>
          </RadioGroup>
        </Box>
        {/* establishment view */}
        {singlePackerPlant && !singlePackerPlant.isPacker && (
          <div style={{ width: '100%' }}>
            <div
              className={styles.Qps_score}
              data-large
              style={{
                marginBottom: 20,
                marginRight: 0,
                marginLeft:
                  bucketWidthMode === 'count'
                    ? `calc(${
                        (scorecardData.allPackerPlants.findIndex(x => x.packer_plant_name === singlePackerPlant.name) *
                          100) /
                        scorecardData.total.count
                      }%)`
                    : `calc(${
                        (scorecardData.allPackerPlants
                          .slice(
                            0,
                            scorecardData.allPackerPlants.findIndex(x => x.packer_plant_name === singlePackerPlant.name)
                          )
                          .reduce((a, b) => a + b.volume, 0) *
                          100) /
                        scorecardData.total.volume
                      }%)`,
              }}
            >
              {singlePackerPlantScore}{' '}
              <div className={styles.Qps_tag}>
                <Triangle color={getColor(singlePackerPlantScore)} />
              </div>
              <div className={styles.Qps_pointer}>
                <Pointer />
              </div>
            </div>
          </div>
        )}
        {/* packer view when it has only one establishment */}
        {singlePackerPlant && singlePackerPlant.isPacker && (
          <div style={{ width: '100%', display: 'flex', height: 20 }}>
            {scorecardData.packerPlantsInPacker.map(packerPlant => (
              <Tooltip content={packerPlant.packer_plant_name} key={packerPlant.packer_plant_name}>
                <div
                  className={styles.Qps_scoreMulti}
                  data-large
                  style={{
                    marginRight: 0,
                    marginLeft:
                      bucketWidthMode === 'count'
                        ? `calc(${
                            (scorecardData.allPackerPlants.findIndex(
                              x => x.packer_plant_name === packerPlant.packer_plant_name
                            ) *
                              100) /
                            scorecardData.total.count
                          }%)`
                        : `calc(${
                            (scorecardData.allPackerPlants
                              .slice(
                                0,
                                scorecardData.allPackerPlants.findIndex(
                                  x => x.packer_plant_name === packerPlant.packer_plant_name
                                )
                              )
                              .reduce((a, b) => a + b.volume, 0) *
                              100) /
                            scorecardData.total.volume
                          }%)`,
                  }}
                >
                  <div className={styles.Qps_pointer}>
                    <Pointer />
                  </div>
                </div>
              </Tooltip>
            ))}
          </div>
        )}
        {/* default view for all */}
        <div
          style={{
            height: 12,
            width: '100%',
            display: 'flex',
          }}
        >
          {barScoreCardData &&
            barScoreCardData.buckets.map((x, i) => {
              return (
                <Tooltip key={`bucket-${random()}`} content={`${x.count} - ${getscoretooltiptext(x.bucket)}`}>
                  <div
                    style={{
                      width: `${(x[bucketWidthMode] * 100) / barScoreCardData.total[bucketWidthMode]}%`,
                      backgroundColor: getColor(x.bucket),
                      borderRadius: getBorderRadius(i, barScoreCardData.buckets.length),
                    }}
                  />
                </Tooltip>
              );
            })}
        </div>
        {/* packer view with more than 1 est */}
        {!singlePackerPlant || singlePackerPlant.isPacker ? (
          <p style={{ color: 'rgba(11, 20, 53, 0.6)' }}>{`${formatWithCommas(scorecardData.total[bucketWidthMode])} ${
            bucketWidthMode === 'count' ? 'establishments' : 'tonnes'
          }`}</p>
        ) : (
          <Text className={styles.Qps_keyStatistic}>
            <span>{singlePackerPlantRank}</span> out of {scorecardData.total.count} establishments
          </Text>
        )}
      </Box>
      {/* packer with single est or mutiple ests */}
      {(!singlePackerPlant || singlePackerPlant.isPacker || (singlePackerPlant && singlePackerPlant.isPacker)) && (
        <Box style={{ display: 'flex' }}>
          <div className={styles.Qps_table}>
            <div className={styles.Qps_title}>Laggers</div>
            {scorecardData &&
              scorecardData.bottom
                .sort((a, b) => a.score - b.score)
                .map(x => (
                  <div className={styles.Qps_tableRow} key={`laggers-${random()}`}>
                    <div className={styles.Qps_score}>
                      {x.score}{' '}
                      <div className={styles.Qps_tag}>
                        <Triangle color={getColor(x.score)} />
                      </div>
                    </div>
                    <div className={styles.Qps_label}>{x.packer_plant_name}</div>
                  </div>
                ))}
          </div>
          <div className={styles.Qps_table}>
            <div className={styles.Qps_title}>Leaders</div>
            {scorecardData &&
              scorecardData.top.map(x => (
                <div className={styles.Qps_tableRow} key={`packer-plant-${random()}`}>
                  <div className={styles.Qps_score}>
                    {x.score}
                    <div className={styles.Qps_tag}>
                      <Triangle color={getColor(x.score)} />
                    </div>
                  </div>
                  <div className={styles.Qps_label}>{x.packer_plant_name}</div>
                </div>
              ))}
          </div>
        </Box>
      )}
      {/* est view */}
      {singlePackerPlant && !singlePackerPlant.isPacker && (
        <Box>
          <div className={styles.Qps_historyTitle}>
            <Text className={styles.Qps_label}>history</Text>
            <RadioGroup
              onValueChange={e => {
                setDataType(e.value);
              }}
              defaultValue={dataType}
              size="medium"
              className={styles.Qps_radio}
            >
              <HStack alignItems="center">
                <Radio key={1} value="rank">
                  Rank
                </Radio>
                <Radio key={2} value="score">
                  Score
                </Radio>
              </HStack>
            </RadioGroup>
          </div>
          {scorecardData[`${dataType}ByPeriod`] && (
            <BaseChart
              barData={scorecardData[`${dataType}ByPeriod`].map(x => ({
                interval: format(new Date(x.date).getTime(), 'MMM yyyy'),
                value: dataType === 'rank' ? maxNumOfPackerPlants - x.value : x.value,
                total: dataType === 'rank' ? maxNumOfPackerPlants - x.total : x.value,
              }))}
              columns={dataType === 'rank' ? ['value', 'total'] : ['value']}
              colors={{ value: '#959BB5' }}
              lastBarColor="#4E5984"
              splitIndex={-1}
              noCurrentLine
              formatIntervals={formatIntervals}
              noLegend
              customContent={({ barData, scales }) => customContent(barData, scales)}
              formatValues={val => {
                if (dataType === 'rank') {
                  return maxNumOfPackerPlants - val;
                }
                return val;
              }}
              ticksNumber={ticksNumber}
              tooltipLabels={{ value: dataType === 'rank' ? 'Ranking' : 'Score', total: 'Total establishments' }}
              formatTooltip={val => {
                if (val === -1) {
                  return 'No data';
                }
                if (dataType === 'rank') {
                  return maxNumOfPackerPlants - val;
                }
                return val;
              }}
              isSmall
              minMaxGenerator={() => {
                if (dataType === 'rank') {
                  return {
                    maxVal: maxNumOfPackerPlants - 1,
                    minVal: 0,
                  };
                }
                if (dataType === 'score') {
                  return {
                    maxVal: 100,
                    minVal: 0,
                  };
                }

                return {
                  maxVal: 0,
                  minVal: 0,
                };
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

QualityPackerPlantScorecard.propTypes = {
  scorecardData: PropTypes.shape({
    allPackerPlants: PropTypes.arrayOf(
      PropTypes.shape({
        packer_plant_name: PropTypes.string,
        score: PropTypes.number,
        volume: PropTypes.number,
      })
    ),
    rankByPeriod: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        value: PropTypes.number,
        total: PropTypes.number,
      })
    ),
    buckets: PropTypes.arrayOf(
      PropTypes.shape({
        bucket: PropTypes.number,
        count: PropTypes.number,
        volume: PropTypes.number,
      })
    ),
    total: PropTypes.shape({
      count: PropTypes.number,
      volume: PropTypes.number,
    }),
    bottom: PropTypes.arrayOf(
      PropTypes.shape({
        score: PropTypes.number,
        packer_plant_name: PropTypes.string,
      })
    ),
    top: PropTypes.arrayOf(
      PropTypes.shape({
        score: PropTypes.number,
        packer_plant_name: PropTypes.string,
      })
    ),
    packerPlantsInPacker: PropTypes.arrayOf(
      PropTypes.shape({
        packer_plant_name: PropTypes.string,
      })
    ),
  }),
  singlePackerPlant: PropTypes.shape({
    isPacker: PropTypes.bool,
    name: PropTypes.string,
  }),
  formatIntervals: PropTypes.func,
  timePeriod: PropTypes.string,
};

const COLORS = { value: '#959BB5' };

function Range(props) {
  const { data, scales, showRange, columns } = props;

  const bandWidth = scales.x.bandwidth();

  return (
    <>
      {data.map((d, i) => {
        // const values = columns.map(c => d[c]);

        // const yMax = values.length ? Math.max(...values) : 0;
        // const yMin = values.length ? Math.min(...values) : 0;
        return (
          <g key={`${Math.random()}-bars`}>
            <g>
              {showRange && d.total && (
                <rect
                  x={scales.x(d.interval)}
                  y={scales.y(d.total)}
                  width={bandWidth}
                  height={scales.y(33 - d.total) - scales.y(33)}
                  fill="#0B1435"
                  opacity={0.1}
                />
              )}
              {columns.map(c => {
                const color = COLORS[c];
                return (
                  <g key={`line-${c}`}>
                    <line
                      x1={scales.x(d.interval)}
                      y1={scales.y(d[c])}
                      x2={scales.x(d.interval) + bandWidth}
                      y2={scales.y(d[c])}
                      stroke={color}
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                    {data[i + 1] !== undefined && data[i + 1][c] !== undefined && (
                      <line
                        x1={scales.x(d.interval) + bandWidth}
                        y1={scales.y(d[c])}
                        x2={scales.x(d.interval) + bandWidth}
                        y2={scales.y(data[i + 1][c])}
                        stroke={color}
                        strokeLinecap="round"
                        strokeWidth={2}
                      />
                    )}
                  </g>
                );
              })}
            </g>
          </g>
        );
      })}
    </>
  );
}

Range.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      interval: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      total: PropTypes.number,
    })
  ),
  scales: PropTypes.shape({
    x: PropTypes.func.isRequired,
    y: PropTypes.func.isRequired,
  }),
  showRange: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.string),
};
