import React from 'react';
import { Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import styles from './FilterTitle.module.css';

export default function FilterTitle({ marginTop, children }) {
  return (
    <Text strong={false} textStyle="md" className={styles.filterTitle} style={{ fontWeight: 'normal', marginTop }}>
      {children}
    </Text>
  );
}

FilterTitle.propTypes = {
  marginTop: PropTypes.number,
  children: PropTypes.node,
};
