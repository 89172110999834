import React from 'react';
import PropTypes from 'prop-types';
import { group, max, mean, scaleLinear } from 'd3';
import { format } from 'date-fns';
import COLORS from '../../../../modules/colors';
import { formatMarketQuantity } from '../../../../modules/format';
import { SUPPLY_CHAIN_VIEW_MODE, DATE_MODE } from '../../../../slices/markets/marketsConfig';
import styles from './SupplyChainTable.module.css';
import { Tooltip } from '@/components/ui/tooltip';
import { Text } from '@chakra-ui/react';

const sourceColourScale = scaleLinear().range(['#EEE', '#0B1435']).domain([0, 0.8]);

export default function SupplyChainTable(props) {
  const { marketSupplyChainData, controlState, onHoveredLocationChange, hoveredLocationId, activeMarket } = props;
  const data =
    controlState.viewMode === SUPPLY_CHAIN_VIEW_MODE.GRINDERS
      ? marketSupplyChainData.grinders
      : marketSupplyChainData.packerPlants;

  const scheduleVis = row => {
    if (!row.schedule) {
      return <div />;
    }
    const grouped = group(row.schedule, x => format(x.date, 'EEE'));
    const res = Array.from(grouped.entries()).map(([day, dayVolumes]) => {
      return {
        date: day,
        volume: mean(dayVolumes, d => d.volume),
      };
    });
    const blockWidthPercent = 1 / res.length;
    const maxVolume = max(res, d => d.volume);
    const heightScale = scaleLinear().range([0, 15]).domain([0, maxVolume]);

    // TODO: colour based on incidents.
    const color = '#0B1435';

    const scheduleBlocks = res.map(day => {
      return (
        <div
          key={day.date.toString()}
          className={styles.scheduleVisBlock}
          style={{
            width: `${blockWidthPercent * 100}%`,
            height: heightScale(day.volume),
            backgroundColor: color,
            opacity: 0.8,
          }}
        />
      );
    });

    return (
      <Tooltip content="Average per day Monday - Sunday">
        <div className={styles.schedule}>
          <div className={styles.scheduleVis}>{scheduleBlocks}</div>
          <Text style={{ marginRight: '20px' }}>{formatMarketQuantity(activeMarket, 1, 1)(row.totalVolume)}</Text>
        </div>
      </Tooltip>
    );
  };

  const sourceVis = row => {
    const sourceBlocks = row.orders
      .sort((a, b) => b.volume - a.volume)
      .map(dest => {
        const percentOfGrinderSupply = dest.volume / row.totalVolume;

        const color = sourceColourScale(percentOfGrinderSupply);

        return (
          <div
            key={dest.id}
            className={styles.sourceVisBlock}
            style={{
              width: `${percentOfGrinderSupply * 100}%`,
              backgroundColor: color,
            }}
          />
        );
      });

    return (
      <div className={styles.source}>
        <Text>{sourceBlocks.length}</Text>
        <div className={styles.sourceVis}>{sourceBlocks}</div>
      </div>
    );
  };

  const getMode = () => {
    if (controlState.dateMode === DATE_MODE.ORDER) {
      return 'ordered';
    }
    if (controlState.viewMode === SUPPLY_CHAIN_VIEW_MODE.PACKER_PLANTS) {
      return 'delivered';
    }
    return 'received';
  };

  return (
    <div style={{ display: 'flex' }}>
      <div className={styles.table}>
        <div className={styles.title}>Location</div>
        {data &&
          data.map(x => (
            <div className={styles.tableRow} key={x.id}>
              <div
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: x.id === hoveredLocationId ? 'bold' : 'normal',
                }}
                onMouseEnter={() => {
                  onHoveredLocationChange(x.id);
                }}
                onMouseLeave={() => {
                  onHoveredLocationChange(null);
                }}
              >
                <div
                  className={styles.entity}
                  style={{
                    backgroundColor:
                      controlState.viewMode === SUPPLY_CHAIN_VIEW_MODE.PACKER_PLANTS
                        ? COLORS.PACKER_PLANT
                        : COLORS.GRINDER,
                  }}
                />{' '}
                {x.name}
              </div>
            </div>
          ))}
      </div>
      <div className={styles.table}>
        <div className={styles.title}>{`Volume ${getMode()} (${activeMarket.quantityUnit})`}</div>
        {data &&
          data.map(x => (
            <div className={styles.tableRow} key={x.id}>
              <div style={{ width: '100%' }}>{scheduleVis(x)}</div>
            </div>
          ))}
      </div>
      <div className={styles.table}>
        <div className={styles.title} style={{ textAlign: 'center' }}>
          {controlState.viewMode === SUPPLY_CHAIN_VIEW_MODE.GRINDERS ? 'Sources' : 'Destinations'}
        </div>
        {data &&
          data.map(x => (
            <div className={styles.tableRow} key={x.id}>
              <div style={{ width: '100%' }}>{sourceVis(x)}</div>
            </div>
          ))}
      </div>
    </div>
  );
}

SupplyChainTable.propTypes = {
  controlState: PropTypes.shape({
    dateMode: PropTypes.string,
    viewMode: PropTypes.string,
  }).isRequired,
  marketSupplyChainData: PropTypes.shape({
    grinders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        location: PropTypes.shape({
          geometry: PropTypes.shape({ coordinates: PropTypes.arrayOf(PropTypes.number), type: PropTypes.string }),
          properties: PropTypes.shape({
            country: PropTypes.string,
            name: PropTypes.string,
            type: PropTypes.string,
            uid: PropTypes.string,
          }),
          type: PropTypes.string,
        }),
        name: PropTypes.string,
        orders: PropTypes.arrayOf(
          PropTypes.shape({
            from: PropTypes.string,
            id: PropTypes.string,
            name: PropTypes.string,
            schedule: PropTypes.arrayOf(PropTypes.shape({ date: PropTypes.date, volume: PropTypes.number })),
            volume: PropTypes.number,
          })
        ),
        schedule: PropTypes.arrayOf(PropTypes.shape({ date: PropTypes.date, volume: PropTypes.number })),
        totalVolume: PropTypes.number,
      })
    ),
    packerPlants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        orders: PropTypes.arrayOf(
          PropTypes.shape({
            from: PropTypes.string,
            id: PropTypes.string,
            name: PropTypes.string,
            schedule: PropTypes.arrayOf(PropTypes.shape({ date: PropTypes.date, volume: PropTypes.number })),
            volume: PropTypes.number,
          })
        ),
        schedule: PropTypes.arrayOf(PropTypes.shape({ date: PropTypes.date, volume: PropTypes.number })),
        totalVolume: PropTypes.number,
      })
    ),
  }).isRequired,
  onHoveredLocationChange: PropTypes.func.isRequired,
  hoveredLocationId: PropTypes.string,
  activeMarket: PropTypes.shape({
    allowExportFocus: PropTypes.bool,
    bounds: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    currency: PropTypes.string,
    hasSustainabilityMetrics: PropTypes.bool,
    includeUsdaCalculations: PropTypes.bool,
    includedCountries: PropTypes.arrayOf(PropTypes.string),
    key: PropTypes.string,
    label: PropTypes.string,
    menuItems: PropTypes.arrayOf(
      PropTypes.shape({ calculatePrice: PropTypes.func, id: PropTypes.string, label: PropTypes.string })
    ),
    quantityUnit: PropTypes.string,
    selectorText: PropTypes.string,
    volumeGroupings: PropTypes.arrayOf(
      PropTypes.shape({
        formatter: PropTypes.func,
        getStatisticValue: PropTypes.func,
        groupRowOverride: PropTypes.func,
        key: PropTypes.string,
        label: PropTypes.string,
        onlyShowForMarketFocus: PropTypes.arrayOf(PropTypes.string),
        options: PropTypes.arrayOf(
          PropTypes.shape({
            default: PropTypes.bool,
            key: PropTypes.string,
            label: PropTypes.string,
            type: PropTypes.string,
          })
        ),
        precision: PropTypes.number,
        rowGroupingKey: PropTypes.string,
        tableColumns: PropTypes.arrayOf(
          PropTypes.shape({
            getRowValue: PropTypes.func,
            getTitle: PropTypes.func,
            key: PropTypes.string,
            renderValue: PropTypes.func,
          })
        ),
        title: PropTypes.string,
      })
    ),
  }).isRequired,
};
