import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './ColoredCheckboxGroup.module.css';
import { Checkbox } from '@/components/ui/checkbox';

export default function ColouredCheckboxGroup(props) {
  const { value, defaultValue, options, onChange, disabled } = props;
  const [stateValue, setValue] = React.useState(value || defaultValue || []);

  const handleChange = event => {
    const changedValue = event.target.value;
    const newValue = [...stateValue];
    const index = stateValue.indexOf(changedValue);

    if (index === -1) {
      newValue.push(changedValue);
    } else {
      newValue.splice(index, 1);
    }

    const orderedValues = props.options.filter(option => newValue.includes(option.value)).map(option => option.value);

    if (!('value' in props)) {
      setValue(orderedValues);
    }

    return onChange && onChange(orderedValues);
  };

  useEffect(() => {
    if ('value' in props) {
      setValue(value || []);
    }
  }, [value]);

  return (
    <ul className={styles.colouredCheckboxGroup}>
      {options.map(option => {
        const colour = (option.color && option.color.slice(1).toUpperCase()) || 'Default';

        return (
          <li key={option.value}>
            <Checkbox
              className={styles[`colouredCheckbox${colour}`]}
              key={option.value}
              disabled={'disabled' in option ? option.disabled : disabled}
              value={option.value}
              checked={value.includes(option.value)}
              onChange={handleChange}
              style={option.style}
            >
              {option.label}
            </Checkbox>
          </li>
        );
      })}
    </ul>
  );
}

ColouredCheckboxGroup.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
