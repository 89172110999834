import React, { Fragment } from 'react';
import { ExclamationMarkIcon } from '../../../../shared/Icons';
import styles from './SupplyChainIncidents.module.css';
import PropTypes from 'prop-types';
import { Collapsible } from '@chakra-ui/react';

export default function SupplyChainIncidents(props) {
  const { incidents } = props;

  return (
    <Collapsible.Root>
      {incidents.map(x => {
        return (
          <Fragment key={x.title}>
            <Collapsible.Trigger>
              <div className={styles.title}>
                <div className={styles.icon}>
                  <ExclamationMarkIcon />
                </div>
                {x.title}
              </div>
            </Collapsible.Trigger>
            <Collapsible.Content key={x.title}>
              <div>
                {x.description}{' '}
                <a className={styles.link} href={x.link} target="_blank" rel="noreferrer">
                  {x.link_label}
                </a>
              </div>
            </Collapsible.Content>
          </Fragment>
        );
      })}
    </Collapsible.Root>
  );
}

SupplyChainIncidents.propTypes = {
  incidents: PropTypes.arrayOf({
    title: PropTypes.string,
    description: PropTypes.string,
    link_label: PropTypes.string,
  }),
};
