import React from 'react';
import PropTypes from 'prop-types';
import { DATE_MODE, SUPPLY_CHAIN_VIEW_MODE } from '../../../../slices/markets/marketsConfig';
import { RadioGroup, Radio } from '@/components/ui/radio';
import { HStack } from '@chakra-ui/react';

export default function SupplyChainDateModeControl(props) {
  const { controlState, onDateModeChange } = props;

  const dateModeOptions = Object.entries(DATE_MODE).map(([key, value]) => {
    let label = '';
    if (key === 'DELIVERY') {
      if (controlState.viewMode === SUPPLY_CHAIN_VIEW_MODE.PACKER_PLANTS) {
        label = 'Delivered';
      } else {
        label = 'Received';
      }
    } else {
      label = 'Ordered';
    }
    return {
      label,
      value,
    };
  });

  return (
    <div>
      <RadioGroup value={controlState.dateMode} onValueChange={e => onDateModeChange(e.value)}>
        <HStack alignItems="center" justifyContent="end">
          {dateModeOptions.map(option => (
            <Radio key={option.value} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </HStack>
      </RadioGroup>
    </div>
  );
}

SupplyChainDateModeControl.propTypes = {
  controlState: PropTypes.shape({
    viewMode: PropTypes.string,
    dateMode: PropTypes.string,
  }),
  onDateModeChange: PropTypes.func,
};
