import React, { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { HStack } from '@chakra-ui/react';
import { MARKETS } from '../../../../config/markets';
import styles from './FilterMarket.module.css';
import { RadioGroup, Radio } from '@/components/ui/radio';

const FilterMarket = ({ markets, activeMarket, onActiveMarketChange }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { market } = useParams();

  const onChange = event => {
    onActiveMarketChange(event.value);
    const newPath = pathname.replace(market, MARKETS[event.value].url);
    navigate(newPath);
  };

  useEffect(() => {
    if (market === MARKETS.Australia.url) {
      onActiveMarketChange(MARKETS.Australia.key);
    }
    if (market === MARKETS.USA.url) {
      onActiveMarketChange(MARKETS.USA.key);
    }
  }, [market, onActiveMarketChange]);

  return (
    <>
      <RadioGroup
        onValueChange={onChange}
        defaultValue={activeMarket}
        value={activeMarket}
        className={styles.FilterMarket_radioGroup}
      >
        <HStack alignItems="center" justifyContent="end">
          {markets.map(m => (
            <Radio key={m.key} value={m.key}>
              {m.label}
            </Radio>
          ))}
        </HStack>
      </RadioGroup>
    </>
  );
};

export default FilterMarket;

FilterMarket.propTypes = {
  markets: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeMarket: PropTypes.string.isRequired,
  onActiveMarketChange: PropTypes.func.isRequired,
};
